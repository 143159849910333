import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import React, { memo } from 'react'
import Fade from 'react-reveal/Fade'

export interface Props {
  cta?: ButtonProps[]
  description?: string
  title?: string
}

export const SimpleIntro = memo(function SimpleIntro({
  cta,
  description,
  title,
}: Props) {
  return (
    <Container>
      <Wrapper>
        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}
        {description ? (
          <FadeInUp>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </FadeInUp>
        ) : null}
        {cta
          ? cta.map((item, index) => (
              <Fade bottom key={index}>
                <Button {...item} variant="corners" />
              </Fade>
            ))
          : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 8.75rem auto;
  padding: 0 10.972vw;
  text-align: center;

  @media (max-width: 1199px) {
    padding-right: 1.9375rem;
    padding-left: 1.9375rem;
  }

  @media (max-width: 1023px) {
    margin: 7.5rem auto;
  }
`

const Wrapper = styled.div`
  max-width: 744px;
  margin: auto;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: 500;
  font-size: 3.0625rem;
  text-align: center;

  @media (max-width: 1023px) {
    font-size: 25px;
  }
`

const Description = styled.div`
  margin-top: 1.875rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: normal;
  font-size: 0.9375rem;
  line-height: 1.75rem;
  text-align: center;

  p {
    margin-top: inherit;
  }
`

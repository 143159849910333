import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'

export interface Props {
  className?: string
  dataModal?: number
  distance?: string
  latitude: string
  longitude: string
  onClick?: (e: any) => void
  time?: string
  title: string
}

export const Poi = memo(function Poi({
  className,
  dataModal,
  distance,
  onClick,
  time,
  title,
}: Props) {
  return (
    <Container className={className} data-modal={dataModal} onClick={onClick}>
      <Title>{title}</Title>
      {distance || time ? (
        <Info className="poi-info" row>
          {distance ? <Distance>{distance}</Distance> : null}
          {time ? <Time>{time}</Time> : null}
        </Info>
      ) : null}
    </Container>
  )
})

const Container = styled.div`
  border-top: 1px solid ${rgba(theme.colors.variants.neutralLight4, 0.7)};
  color: rgba(237, 244, 241, 0.7);
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 600;
  font-size: 0.75rem;
  padding: 1.2rem 0;
  position: relative;
  transition: 0.2s ease-in-out;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  &:first-of-type {
    padding-top: 0;
    border: 0;
  }
  &:last-of-type {
    padding-bottom: 0;
  }
  &:hover,
  &.active {
    color: ${({ theme }) => theme.colors.variants.neutralLight4};
  }
  &.active {
    .poi-info {
      display: flex;
    }
  }
`

const Title = styled.div`
  font-weight: 600;
  text-transform: uppercase;
`

const Info = styled(FlexBox)`
  display: none;
  margin-top: 0.675rem;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 600;
  font-size: 0.625rem;

  > div {
    &:nth-of-type(2) {
      &:before {
        content: '-';
        margin-right: 0.25rem;
      }
    }
  }
`

const Distance = styled.div`
  margin-right: 0.25rem;
`

const Time = styled.div``
